/* eslint-disable @typescript-eslint/no-unused-vars */
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://ddb4f8793f63495e8533f02b3da78599@o1120622.ingest.sentry.io/6156917",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  maxBreadcrumbs: 50,
  debug: false,
  // beforeSend: function (event, hint) {
  // const exception = hint.originalException;
  // if (exception instanceof DatabaseConnectionError) {
  //   event.fingerprint = ['database-connection-error'];
  // }
  // return event;
  // },
});
